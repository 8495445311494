import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getErrorMessage } from 'utils';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const adminDashboardAsync = createAsyncThunk(
    'adminDashboardAsync',
    async (dispatch) => {
        dispatch(loaderStart());

        return await Response('GET_ADMIN_DASHBOARD_COUNTS')
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success)
                    return {
                        siteCount: data.siteCount,
                        siteSessionsCount: data.siteSessionsCount,
                        tappCount: data.tappCount,
                        userCount: data.userCount
                    }

                return {
                    siteCount: 0,
                    siteSessionsCount: 0,
                    tappCount: 0,
                    userCount: 0
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {
                    siteCount: 0,
                    siteSessionsCount: 0,
                    tappCount: 0,
                    userCount: 0
                }
            })
    }
);

export const userDashboardAsync = createAsyncThunk(
    'userDashboardAsync',
    async ({ dispatch, siteId }) => {

        dispatch(loaderStart());

        return await Response('GET_USER_DASHBOARD_COUNTS', { siteId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success)
                    return {
                        siteCount: data.siteCount,
                        siteSessionsCount: data.siteSessionsCount,
                        tappCount: data.tappCount
                    }

                return {
                    siteCount: 0,
                    siteSessionsCount: 0,
                    tappCount: 0
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {
                    siteCount: 0,
                    siteSessionsCount: 0,
                    tappCount: 0
                }
            })
    }
);

export const clickImpressionAnalyticAsync = createAsyncThunk(
    'clickImpressionAnalyticAsync',
    async ({ dispatch, postData }) => {
        dispatch(loaderStart());

        return await Response('GET_DATA_ANALYTIC', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data.resultObj.length > 0) {
                    return {
                        clickCount: data.resultObj[0].clicks,
                        earnOnClicks: data.resultObj[0].earnOnClicks,
                        impressionCount: data.resultObj[0].impressions,
                        earnOnImpressions : data.resultObj[0].earnOnImpressions,
                        earned: data.resultObj[0].totalEarned
                    }
                }
                return {
                    clickCount: 0,
                    earnOnClicks: 0,
                    impressionCount: 0,
                    earnOnImpressions: 0,
                    earned: 0
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {
                    clickCount: 0,
                    impressionCount: 0,
                    earned: 0
                }
            })
    }
);

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {

        dashboardData: {},
        clickAnalyticData: {}
    },
    extraReducers: {

        [adminDashboardAsync.fulfilled]: (state, { payload }) => {
            state.dashboardData = payload
        },

        [userDashboardAsync.fulfilled]: (state, { payload }) => {
            state.dashboardData = payload
        },

        [clickImpressionAnalyticAsync.fulfilled]: (state, { payload }) => {
            state.clickAnalyticData = payload
        }
    }
});

export const dashboardState = (state) => state.dashboard;

export default dashboardSlice.reducer;