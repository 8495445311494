import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { authRoles } from '../config/roles'

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
// Installation
const Installation = Loadable(lazy(() => import('../views/pages/installation/Installation')));
// Site
const Site = Loadable(lazy(() => import('../views/pages/site/Site')));
// Profile
const Ads = Loadable(lazy(() => import('../views/pages/ads/Ads')));
// Ads Script
const Profile = Loadable(lazy(() => import('../views/pages/profile/Profile')));
// Words
const Word = Loadable(lazy(() => import('../views/pages/word/Word')))
// ManageUser
const ManageUser = Loadable(lazy(() => import('../views/pages/manageuser/ManageUser')))
// RateMaster
const RateMaster = Loadable(lazy(() => import('../views/pages/ratemaster/RateMaster')))
//RateAssignee
const RateAssigned = Loadable(lazy(() => import('../views/pages/rateassigne/RateAssignee')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard/default',
            element: <DashboardDefault />,
            role: authRoles.user,
        },
        {
            path: '/utils/util-installation',
            element: <Installation />,
            role: authRoles.manager,
        },
        {
            path: '/utils/util-sites',
            element: <Site />,
            role: authRoles.manager,
        },
        {
            path: '/utils/util-ads',
            element: <Ads />,
            role: authRoles.manager,
        },
        {
            path: '/view/view-profile',
            element: <Profile />,
            role: authRoles.manager,
        },
        {
            path: '/words/reserve-words',
            element: <Word />,
            role: authRoles.editer,
        },
        {
            path: '/utils/manage-user',
            element: <ManageUser />,
            role: authRoles.admin,
        },
        {
            path: '/utils/rate-master',
            element: <RateMaster />,
            role: authRoles.admin,
        },
        {
            path: '/utils/rate-assignee',
            element: <RateAssigned />,
            role: authRoles.admin,
        },
        
    ]
};

export default MainRoutes;