// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';
import { authRoles } from 'config/roles';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const words = {
    id: 'wordbank',
    title: 'Word Bank',
    type: 'group',
    children: [
        {
            id: 'word-bank',
            title: 'My Words',
            type: 'item',
            url: '/words/reserve-words',
            icon: icons.IconTypography,
            breadcrumbs: true,
            role : authRoles.editer
        }
    ]
};

export default words;
