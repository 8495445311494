import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { getEmptyData } from '../../utils/empty-types';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const siteAsync = createAsyncThunk(
    'siteAsync',
    async (dispatch) => {

        dispatch(loaderStart());

        return await Response('GET_USER_SITES')
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success)
                    return data.sites

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const siteListAsync = createAsyncThunk(
    'siteListAsync',
    async ({ dispatch, page }) => {

        dispatch(loaderStart());

        return await Response('GET_SITE_LIST', { page })
            .then((data) => {

                dispatch(loaderEnd());
                if (data.success)
                    
                    return data.sites

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const saveSiteAsync = createAsyncThunk(
    'saveSiteAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('SAVE_SITE', postData)
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(setAlertMessage("Site data saved successfully"));

                    dispatch(siteListAsync({ dispatch, page: 1 }));

                    dispatch(siteAsync(dispatch));
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));
            })
    }
);

export const siteVerifyAsync = createAsyncThunk(
    'siteVerifyAsync',
    async ({ dispatch, siteId }) => {

        dispatch(loaderStart());

        return await Response('GET_SITE_BT_ID', { siteId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success)
                    return { isVerified: data.isVerified, siteSettings: data.siteSettings }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const siteSlice = createSlice({
    name: "site",
    initialState: {

        siteData: [],

        siteList: [],

        siteVerification: false,

        selectedSite: '',

        siteName: '',

        siteLanguage: 'hindi'
    },
    reducers: {

        setCurrentSite: (state, { payload }) => {

            state.selectedSite = payload.id;
            state.siteName = payload.name;
            state.siteLanguage = payload.language;
        },

        clearSiteState: (state, { payload }) => {

            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        [siteAsync.fulfilled]: (state, { payload }) => {

            state.siteData = payload
        },

        [siteListAsync.fulfilled]: (state, { payload }) => {

            state.siteList = payload
        },

        [siteVerifyAsync.fulfilled]: (state, { payload }) => {

            state.siteVerification = payload.isVerified
        }
    }
});

export const { setCurrentSite } = siteSlice.actions;

export const siteState = (state) => state.site;

export default siteSlice.reducer;