// assets
import { IconCurrencyRupee, IconUser, IconUsers } from '@tabler/icons';
import { authRoles } from 'config/roles';

// constant
const icons = {
    IconUser,
    IconUsers,
    IconCurrencyRupee
};

// ==============================|| PROFILES MENU ITEMS ||============================== //

const profile = {
    id: 'profile',
    title: 'Profile',
    type: 'group',
    children: [
        {
            id: 'view-profile',
            title: 'Edit Profile',
            type: 'item',
            url: '/view/view-profile',
            icon: icons.IconUser,
            breadcrumbs: true,
            role : authRoles.manager,
        },
        {
            id: 'rates',
            title: 'Rate Master',
            type: 'item',
            url: '/utils/rate-master',
            icon: icons.IconCurrencyRupee,
            breadcrumbs: true,
            role : authRoles.admin,
        },
        {
            id: 'rate-assignee',
            title: 'Rate Assignee',
            type: 'item',
            url: '/utils/rate-assignee',
            icon: icons.IconCurrencyRupee,
            breadcrumbs: true,
            role : authRoles.admin,
        },
        {
            id: 'manage',
            title: 'Manage User',
            type: 'item',
            url: '/utils/manage-user',
            icon: icons.IconUsers,
            breadcrumbs: true,
            role : authRoles.admin,
        },
        // {
        //     id: 'rates',
        //     title: 'Rates',
        //     type: 'item',
        //     url: '/views/pages/Rates',
        //     icon: icons.IconUsers,
        //     breadcrumbs: true,
        //     role : authRoles.admin,
        // },
    ]
};

export default profile;
