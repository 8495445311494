// assets
import { IconBrandDribbble, IconApps } from '@tabler/icons';
import { authRoles } from 'config/roles';

// constant
const icons = {
    IconBrandDribbble,
    IconApps
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-installation',
            title: 'Installation',
            type: 'item',
            url: '/utils/util-installation',
            icon: icons.IconApps,
            breadcrumbs: true,
            role: authRoles.manager
        },
        {
            id: 'util-sites',
            title: 'Sites',
            type: 'item',
            url: '/utils/util-sites',
            icon: icons.IconBrandDribbble,
            breadcrumbs: true,
            role: authRoles.manager
        }
        // {
        //     id: 'util-ads',
        //     title: 'Ads',
        //     type: 'item',
        //     url: '/utils/util-ads',
        //     icon: icons.IconBrandDribbble,
        //     breadcrumbs: true,
        //     role : authRoles.manager
        // }
    ]
};

export default utilities;
