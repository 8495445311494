import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getRatesAsync = createAsyncThunk(
    'getRatesAsync', async (dispatch) => {
        dispatch(loaderStart());
        return await Response('GET_RATES')
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {
                    return data.resultObj;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
);

export const saveRateAsync = createAsyncThunk(
    'saveRateAsync', async ({ dispatch, postData }) => {
        console.log(postData)
        dispatch(loaderStart());

        return await Response('ADD_RATES', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(getRatesAsync(dispatch));

                    return data

                }

                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const rate = createSlice({

    name: "rate",

    initialState: {

        rateData: [],
        
    },
    
    extraReducers: {

        [getRatesAsync.fulfilled]: (state, { payload }) => {

            state.rateData = payload
        }
    }
});

export const rateState = (state) => state.rate;

export default rate.reducer;