export default {
  LOGIN_USER: 'POST /auth/login',

  GET_CURRENT_USER: '/api/user/current',
  GET_USER_SITES: '/api/user-sites', // for header dropdown
  GET_USER_DASHBOARD_COUNTS: '/api/user-dashboard-count?',
  GET_ADMIN_DASHBOARD_COUNTS: '/api/analytics-count',
  GET_SITE_LIST: '/api/list/sites?',
  GET_SITE_BT_ID: '/api/site-data?',

  SAVE_SITE : 'POST /api/add/site',
  SEARCH_WORD : '/api/search-word?',
  SAVE_WORD : 'POST /api/save-word',
  DELETE_WORD : '/api/delete-word?',
  GET_ALL_WORDS : 'POST /api/word-list',

  UPLOAD_WORD_IMAGE : 'POST /api/upload-word-image',
  UPLOAD_WORD_IMAGE_COMPRESS : 'POST /api/upload-word-image-compress',
  DELETE_UPLOADED_IMAGE : 'DELETE /api/delete-uploaded-image',
  // SAVE_SITE : 'POST /api/add/site',
  GET_USER_LIST: '/api/get-all-users?',
  SAVE_USER : 'POST /auth/register',
  DELETE_USER : 'POST /auth/delete-user',
  EDIT_PASSWORD : 'PUT /auth/changePassword', // For Admin
  UPDATE_PASSWORD_BY_EMAIL : 'PUT /auth/user-change-password',
  UPDATE_USER_PROFILE : 'POST /auth/update-user-profile',
  UPLOAD_PROFILE : 'POST /api/upload/profile-photo',

  GET_SCRIPT : '/api/get/script',
  GET_SCRIPT_BYID : '/api/getById#',
  SAVE_SCRIPT : 'POST /api/add/script',
  DELETE_SCRIPT : 'DELETE /api/delete#',

  //Analysis
  GET_RATES : '/api/get/rates', // For Admin
  ADD_RATES : 'POST /api/add/rate',
  GET_DATA_ANALYTIC : "POST /api/get/data_analytics",
  GET_SITES_RATES : "POST /api/get/site-rate",
  GET_RATE_ASSIGNED_ID : "/api/get/rateassigned#",
  ASSIGNEE_RATE : 'POST /api/assigne/rate',
  GET_ASSIGNED_RATES : 'POST /api/get/assigned/rate'
}
