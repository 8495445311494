import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { getEmptyData } from 'utils/empty-types';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getAllWordAsync = createAsyncThunk(
    'getAllWordAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('GET_ALL_WORDS', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success)
                    return data

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const getSearchWordAsync = createAsyncThunk(
    'getSearchWordAsync',
    async ({ dispatch, searchData }) => {

        dispatch(loaderStart());
        return await Response('SEARCH_WORD', searchData)
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success)
                    return data.data;
                else {
                    dispatch(setAlertMessage("Word not found"));

                    return {}
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const saveWordAsync = createAsyncThunk(
    'saveWordAsync',
    async ({ dispatch, wordData, type }) => {

        dispatch(loaderStart());

        return await Response('SAVE_WORD', wordData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(setAlertMessage(`Word ${type} successfully`));

                    const postData = { page: "", limit: 100, siteId: wordData.siteId };

                    dispatch(getAllWordAsync({ dispatch, postData }));

                    dispatch(clearWordState({ key: "wordData", type: "object" }));

                    dispatch(clearWordState({ key: "uploadedImageData", type: "array" }));

                    dispatch(clearWordState({ key: "uploadedCompressImageData", type: "array" }));

                    return data;
                }

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const deleteWordAsync = createAsyncThunk(
    'deleteWordAsync',
    async ({ dispatch, postData, siteId }) => {

        dispatch(loaderStart());

        return await Response('DELETE_WORD', { ...postData, siteId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    dispatch(setAlertMessage("Word deleted successfully."));

                    const postData = { page: "", limit: 100, siteId };

                    dispatch(getAllWordAsync({ dispatch, postData }));
                }

            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));
            })
    }
);

export const uploadWordImageAsync = createAsyncThunk(
    'uploadWordImageAsync',
    async ({ dispatch, postData, type }) => {

        dispatch(loaderStart());

        return await Response(type === 'original' ? 'UPLOAD_WORD_IMAGE' : 'UPLOAD_WORD_IMAGE_COMPRESS', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    return { ...data, type };
                }

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const removeUploadedImageAsync = createAsyncThunk(
    'removeUploadedImageAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('DELETE_UPLOADED_IMAGE', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    // do nothing for now
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));
            })
    }
);

export const searchSlice = createSlice({
    name: "word",
    initialState: {

        wordData: {},

        wordList: {},

        uploadedImageData: [],

        uploadedCompressImageData: []
    },
    reducers: {

        clearWordState: (state, { payload }) => {

            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        [getSearchWordAsync.fulfilled]: (state, { payload }) => {
            state.wordData = payload
        },

        [getAllWordAsync.fulfilled]: (state, { payload }) => {

            state.wordList = payload.data
        },

        [uploadWordImageAsync.fulfilled]: (state, { payload }) => {

            const storage = payload.type === 'original' ? "uploadedImageData" : "uploadedCompressImageData";

            state[storage] = payload.data
        }
    }
});

export const { clearWordState } = searchSlice.actions;

export const searchState = (state) => state.word;

export default searchSlice.reducer;