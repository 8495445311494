import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, MenuItem, TextField, } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// Redux 
import { siteState, siteAsync, setCurrentSite, siteVerifyAsync } from '../../../features/site/siteSlice';
import { adminDashboardAsync, userDashboardAsync, clickImpressionAnalyticAsync } from '../../../features/dashboard/dashboardSlice';
import { getAllWordAsync } from '../../../features/search/searchSlice';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const site_state = useSelector(siteState);

    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState('');
    const [role, setRole] = useState('');
    const [userId, setUserId] = useState('')
    useEffect(() => {

        const profile = JSON.parse(localStorage.getItem("profile"));

        setUserId(...userId, profile._id );

        if (profile) {

            setRole(profile.user_type);

            if (profile.user_type !== "admin") {

                dispatch(siteAsync(dispatch));
            }
            else {
                dispatch(adminDashboardAsync(dispatch));
            }
        }

    }, []);

    useEffect(() => {

        if (site_state.siteData.length > 0) {

            setSites(site_state.siteData);

            if (site_state.siteData[0]._id != undefined) {

                setSelectedSite(site_state.siteData[0]._id);

                dispatch(userDashboardAsync({ dispatch, siteId: site_state.siteData[0]._id }));

                let postData = { siteId: site_state.siteData[0]._id, userId : userId[0]}

                dispatch(clickImpressionAnalyticAsync({ dispatch, postData }))

                // dispatch(clickImpressionAnalyticAsync({ dispatch, siteId: site_state.siteData[0]._id }))

                dispatch(siteVerifyAsync({ dispatch, siteId: site_state.siteData[0]._id }));

                dispatch(setCurrentSite({ id: site_state.siteData[0]._id, name: site_state.siteData[0].name, language: 'hindi' }));

                dispatch(getAllWordAsync({ dispatch, postData : { page: "", limit: 10, siteId :site_state.siteData[0]._id } }));
            }
        }

    }, [site_state.siteData]);

    useEffect(() => {

        if (selectedSite) {

            const siteDetails = site_state.siteData.find(item => item._id === selectedSite);

            if (siteDetails) {

                dispatch(setCurrentSite({ id: selectedSite, name: siteDetails.name, language: 'hindi' }));
            }

            dispatch(siteVerifyAsync({ dispatch, siteId: selectedSite }));

            dispatch(getAllWordAsync({ dispatch, postData: { page: "", limit: 10, siteId: selectedSite } }));
        }

    }, [selectedSite]);

    const handleSiteChange = (siteId) => {

        let postData = { siteId, userId }

        setSelectedSite(siteId);

        dispatch(userDashboardAsync({ dispatch, siteId: siteId }));

        dispatch(clickImpressionAnalyticAsync({ dispatch, postData }));

        // dispatch(clickImpressionAnalyticAsync({ dispatch, siteId: siteId }));
    }

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            {role !== "admin" && sites.length > 0 && <Box sx={{ marginLeft: '10px' }} >
                <TextField
                    style={{ minWidth: "10rem", height: "2rem" }}
                    id="standard-select-currency"
                    select
                    value={selectedSite}
                    size="small"
                    label="Select Website"
                    onChange={(e) => {
                        handleSiteChange(e.target.value);
                    }}
                >
                    <MenuItem value="All">All</MenuItem>
                    {sites.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>}

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
