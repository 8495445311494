import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getSiteRatesAsync = createAsyncThunk(
    'getSiteRatesAsync', async ({dispatch, userId}) => {
        dispatch(loaderStart());
        return await Response('GET_ASSIGNED_RATES', {userId})
            .then((data) => {
                dispatch(loaderEnd());
                if (data.success) {
                    return data.resultObj;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
);

export const eidtRateAssignedAsync = createAsyncThunk(
    'eidtRateAssignedAsync', async ({ rateAssigneeId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('GET_RATE_ASSIGNED_ID', {rateAssigneeId})
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    
                    dispatch(getSiteRatesAsync(dispatch))
                    return data.resultObj;
                }

                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const addEarningRateAsync = createAsyncThunk(
    'addEarningRateAsync', async ({ postData, dispatch }) => {

        dispatch(loaderStart());

        return await Response('ASSIGNEE_RATE', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    
                    return data.resultObj;
                }

                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const siteRate = createSlice({

    name: "rate",

    initialState: {

        siteRateData: [],
        rateAssignedData: {},
        
    },
    
    extraReducers: {

        [getSiteRatesAsync.fulfilled]: (state, { payload }) => {

            state.siteRateData = payload
        },

        [eidtRateAssignedAsync.fulfilled]: (state, { payload }) => {

            state.rateAssignedData = payload
        }
    }
});

export const siteRateState = (state) => state.siteRate;

export default siteRate.reducer;