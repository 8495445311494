import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Response from '../../utils/response';
import { getErrorMessage } from 'utils';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';
import { getCurrentUserAsync } from '../auth/authSlice';


export const userListAsync = createAsyncThunk(
    'userListAsync',
    async ({ dispatch, page }) => {

        dispatch(loaderStart());

        return await Response('GET_USER_LIST', { page })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success)
                    return data.users

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);

export const saveUserAsync = createAsyncThunk(
    'saveUserAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('SAVE_USER', postData)
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(setAlertMessage("User added successfully"));

                    dispatch(userListAsync({ dispatch, page: 1 }));
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));
            })
    }
);


export const deleteUserAsync = createAsyncThunk(
    'deleteUserAsync',
    async ({ userId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('DELETE_USER', { userId })
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(setAlertMessage("User Delete successfully"));

                    dispatch(userListAsync({ dispatch, page: 1 }));
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));
            })
    }
);

export const updatePasswordAsync = createAsyncThunk(
    'updatePasswordAsync',
    async ({ dispatch, postData }) => {
        dispatch(loaderStart());

        return await Response('EDIT_PASSWORD', postData)
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {
                    dispatch(setAlertMessage("Password updated successfully."));
                }

            })
            .catch((error) => {
                dispatch(loaderEnd());
                dispatch(setAlertMessage(error.message));
            })
    }
);

export const updateCurrentUserPasswordAsync = createAsyncThunk(
    'updateCurrentUserPasswordAsync',
    async ({ dispatch, postData, handleLogout }) => {
        dispatch(loaderStart());

        return await Response('UPDATE_PASSWORD_BY_EMAIL', postData)
            .then((data) => {
                
                dispatch(loaderEnd());

                if (data.success) {
                    handleLogout()
                }
                dispatch(setAlertMessage(data.message));

            })
            .catch((error) => {
                dispatch(loaderEnd());
                dispatch(setAlertMessage(error.message));
            })
    }
);


export const updateUserProfiledAsync = createAsyncThunk(
    'updateUserProfiledAsync',
    async ({ dispatch, postData }) => {
        dispatch(loaderStart());

        return await Response('UPDATE_USER_PROFILE', postData)
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {
                    dispatch(setAlertMessage("Profile updated successfully."));

                    const profile = JSON.parse(localStorage.getItem("profile"));

                    const updatedProfile = {...profile, profilePhoto : postData.profilePhoto}

                    localStorage.setItem('profile', JSON.stringify(updatedProfile));

                    dispatch(getCurrentUserAsync(dispatch));
                }
            })
            .catch((error) => {
                dispatch(loaderEnd());
                dispatch(setAlertMessage(error.message));
            })
    }
);

export const uploadProfileAsync = createAsyncThunk(
    'uploadProfileAsync',
    async ({ dispatch, profileData, name }) => {
        dispatch(loaderStart());

        return await Response('UPLOAD_PROFILE', profileData)
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {

                    const postData = { name, profilePhoto: data.image };

                    dispatch(updateUserProfiledAsync({ dispatch, postData }));
                }
            })
            .catch((error) => {
                dispatch(loaderEnd());
                dispatch(setAlertMessage(error.message));
            })
    }
);


export const userSlice = createSlice({
    name: "user",
    initialState: {

        userData: []
    },

    extraReducers: {

        [userListAsync.fulfilled]: (state, { payload }) => {

            state.userData = payload
        },
    }
});


export const userState = (state) => state.user;

export default userSlice.reducer;