import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { getEmptyData } from '../../utils/empty-types';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getScriptAsync = createAsyncThunk(
    'getScriptAsync', async (dispatch) => {

        dispatch(loaderStart());

        return await Response('GET_SCRIPT')
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    return data.resultObj;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
)

export const editScriptByIdAsync = createAsyncThunk(
    'editScriptByIdAsync', async ({ adsId, dispatch }) => {

        dispatch(loaderStart());

        return await Response('GET_SCRIPT_BYID', { adsId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    return data.resultObj;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
)

export const saveScriptAsync = createAsyncThunk(
    'saveScriptAsync', async ({ dispatch, postData }) => {
        dispatch(loaderStart());
        return await Response('SAVE_SCRIPT', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(getScriptAsync(dispatch));

                    return data

                }

                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const deleteScriptAsync = createAsyncThunk(
    'deleteScriptAsync', async ({ id, dispatch }) => {

        dispatch(loaderStart());

        return await Response('DELETE_SCRIPT', { id })

            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(getScriptAsync(dispatch));

                    dispatch(setAlertMessage("Script Delete Successfully"))

                    return data.resultObj;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
)

export const adsSlice = createSlice({

    name: "ads",

    initialState: {

        adsScriptData: [],
        editScriptData: {}

    },
    reducers: {

        clearAdsState: (state, { payload }) => {

            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        [getScriptAsync.fulfilled]: (state, { payload }) => {

            state.adsScriptData = payload
        },

        [editScriptByIdAsync.fulfilled]: (state, { payload }) => {

            state.editScriptData = payload
        }
    }
});

export const { clearAdsState } = adsSlice.actions;

export const adsState = (state) => state.ads;

export default adsSlice.reducer;