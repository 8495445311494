import NavList from '../../menu-items';

import Response from '../../utils/response';

import { getSideNavList } from '../menu/menuSlice';

import { getErrorMessage, getRoleBasedRoutes } from 'utils';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const loginAsync = createAsyncThunk(
    'loginAsync',
    async ({ email, password, dispatch }) => {

        dispatch(loaderStart());

        return await Response('LOGIN_USER', { email, password })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(setAlertMessage("Logged In successfully"));

                    localStorage.setItem('accessToken', data.token.split(" ")[1]);

                    dispatch(getCurrentUserAsync(dispatch));

                    return {}
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return {}
            })
    }
);

export const getCurrentUserAsync = createAsyncThunk(
    'getCurrentUserAsync',
    async (dispatch) => {

        dispatch(loaderStart());

        return await Response('GET_CURRENT_USER')
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    const { _id, name, email, isAdmin, role, profilePhoto } = data;

                    const userData = { _id, name, email, isAdmin, user_type: role, profilePhoto };

                    dispatch(getSideNavList(getRoleBasedRoutes(NavList.items, userData, 'excucuted')));

                    return { user: userData, isLoggedIn: true };
                }

                return { user: {}, isLoggedIn: false }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return { user: {}, isLoggedIn: false };
            })
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState: {

        userData: {},

        isLoggedIn: false
    },
    reducers: {

        logout: (state) => {

            state.userData = {},

                state.isLoggedIn = false,

                localStorage.clear();
        }
    },
    extraReducers: {

        [getCurrentUserAsync.fulfilled]: (state, { payload }) => {

            state.userData = payload.user,

            state.isLoggedIn = payload.isLoggedIn

            localStorage.setItem('profile', JSON.stringify(payload.user));
        }
    }
});

export const { logout } = authSlice.actions;

export const loginState = (state) => state.auth;

export default authSlice.reducer;