import dashboard from './dashboard';
import profile from './profile';
import utilities from './utilities';
import words from './word';

// ==============================|| MENU ITEMS ||============================== //

export default {
    items: [dashboard, utilities, profile, words]
};
