import { configureStore } from '@reduxjs/toolkit';
import menuSlice from 'features/menu/menuSlice';
import authSlice from 'features/auth/authSlice';
import siteSlice from 'features/site/siteSlice';
import userSlice from 'features/user/userSlice';
import loaderSlice from 'features/loader/loaderSlice';
import searchSlice from 'features/search/searchSlice';
import dashboardSlice from 'features/dashboard/dashboardSlice';
import profileSlice from 'features/profile/profileSlice';
import adsSlice from 'features/ads/adsSlice';
import rateSlice from 'features/ratemaster/rateMasterSlice'
import rateAssigneeSlice from 'features/rateassignee/rateAssigneeSlice';

export const store = configureStore({
    reducer: {
        menu: menuSlice,
        auth: authSlice,
        site: siteSlice,
        user: userSlice,
        ads: adsSlice,
        loader: loaderSlice,
        word: searchSlice,
        dashboard: dashboardSlice,
        currentprofile: profileSlice,
        rate : rateSlice,
        siteRate : rateAssigneeSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})